/*
Code from plug-in of Annotorious open source project: https://github.com/recogito/recogito-client-plugins/tree/main/packages/annotorious-shape-labels
*/
html, body {
        margin:0;
        padding:0;
        background-color:#e2e2e2;
        height:100%;
        font-family:Lato;
        line-height:160%;
      }

      .column {
        max-width:700px;
        padding:20px;
        margin:0 auto;
        background-color:#fff;
        height:100%;
        box-sizing:border-box;
      }

      h1 {
        font-size:21px;
        font-weight:normal;
        margin:0;
        padding:0;
      }

      p.instructions {
        padding:10px 0 30px 0;
      }

      .openseadragon-canvas {
        outline:none;
        background-color:#efefef !important;
      }

      .a9s-selection-mask {
        fill:rgba(0,0,0,0.6) !important;
        pointer-events:none;
      }

      .a9s-annotationlayer .a9s-formatter-el,
      .a9s-annotationlayer .a9s-formatter-el foreignObject {
        overflow:visible;
        pointer-events:none;
      }

      .a9s-annotationlayer .a9s-formatter-el foreignObject .a9s-shape-label {
        position:relative;
        top:-2em;
        left:-2px;
        display:table;
        padding:3px 5px;
        white-space:nowrap;
        background-color:rgba(255, 255, 255, 0.85);
        border-radius:3px;
        font-size:14px;
      }
