/*
Code modified from the open source Annotorious ColorFormatter plug-in https://recogito.github.io/guides/editor-widgets/
*/
svg.a9s-annotationlayer .a9s-annotation.RED .a9s-outer {
  stroke:red;
  stroke-width:3;
}

svg.a9s-annotationlayer .a9s-annotation.GREEN .a9s-outer {
  stroke:green;
  stroke-width:3;
}

svg.a9s-annotationlayer .a9s-annotation.BLUE .a9s-outer {
  stroke:blue;
  stroke-width:3;
}

svg.a9s-annotationlayer .a9s-annotation.PURPLE .a9s-outer {
  stroke:rgb(75, 0, 130);
  stroke-width:3;
}

svg.a9s-annotationlayer .a9s-annotation.ORANGE .a9s-outer {
  stroke:rgb(255, 140, 0);
  stroke-width:3;
}

svg.a9s-annotationlayer .a9s-annotation.PINK .a9s-outer {
  stroke:rgb(225, 20, 147);
  stroke-width:3;
}

svg.a9s-annotationlayer .a9s-annotation.YELLOW .a9s-outer {
  stroke:rgb(225, 215, 0);
  stroke-width:3;
}

svg.a9s-annotationlayer .a9s-annotation.BLACK .a9s-outer {
  stroke:rgb(0, 0, 0);
  stroke-width:3;
}

svg.a9s-annotationlayer .a9s-annotation.RED .a9s-inner,
svg.a9s-annotationlayer .a9s-annotation.GREEN .a9s-inner,
svg.a9s-annotationlayer .a9s-annotation.PURPLE .a9s-inner,
svg.a9s-annotationlayer .a9s-annotation.ORANGE .a9s-inner,
svg.a9s-annotationlayer .a9s-annotation.PINK .a9s-inner,
svg.a9s-annotationlayer .a9s-annotation.YELLOW .a9s-inner,
svg.a9s-annotationlayer .a9s-annotation.BLACK .a9s-inner,
svg.a9s-annotationlayer .a9s-annotation.BLUE .a9s-inner {
  fill:transparent;
  stroke:none;
}
